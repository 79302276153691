import { GetStaticProps, GetStaticPropsContext } from 'next';
import { UnableToLoadPageDataError } from 'src/core/common/errors';
import { normalizeError } from 'src/core/common/utils/errors';
import { returnNotFoundPage } from 'src/core/common/utils/returnNotFoundPage';
import { returnQueryParam } from 'src/core/common/utils/returnQueryParam';
import { config } from 'src/config';
import { initializeCategoriesService } from 'src/psychics-categories/utils/initializeCategoriesService';
import { createContentManagerHttpClient } from 'src/core/common/services';
import { CategoriesService } from 'src/psychics-categories/services';
import { QueryCacheFallback } from 'src/core/queryCache/entities';
import { CATEGORY_PAGE_GET_EXPERTS_OPTIONS } from 'src/psychics-categories/constants';
import { isBuildPhase } from 'src/core/common/utils/isBuildPhase';
import { LoggerBuilder } from 'src/core/common/logger/services/LoggerBuilder';
import { ExpertServiceBuilder } from 'src/core/experts/services/ExpertServiceBuilder';
import { ExpertsRequestParams } from 'src/core/experts/entities';
import { BUILD_GET_EXPERTS_PARAMS } from 'src/core/experts/constants';
import { pickRandomNElements } from 'src/core/common/utils/array/pickRandomNElements';
import { CategoryPageProps } from 'src/psychics-categories/entities';

export { default } from 'src/psychics-categories/pages';

export const getStaticProps: GetStaticProps<CategoryPageProps & QueryCacheFallback> = async (
  context: GetStaticPropsContext,
) => {
  const isBuild = isBuildPhase();

  const categoriesService = new CategoriesService(createContentManagerHttpClient());
  const logger = new LoggerBuilder().addConsoleTransport().getResult();
  const expertServiceBuilder = new ExpertServiceBuilder();

  const getExpertsParams: ExpertsRequestParams = isBuild
    ? BUILD_GET_EXPERTS_PARAMS.params
    : CATEGORY_PAGE_GET_EXPERTS_OPTIONS.params;

  if (isBuild) {
    expertServiceBuilder.addCaching();
  }
  const expertService = expertServiceBuilder.getResult();

  const slug = returnQueryParam({ params: context.params, query: 'slug' });

  if (!slug) {
    return returnNotFoundPage();
  }

  try {
    const [content, experts] = await Promise.all([
      categoriesService.getCategory(slug),
      expertService.getExperts(getExpertsParams),
    ]);

    if (!content.found) {
      return returnNotFoundPage();
    }

    return {
      props: {
        metaData: content.metaData,
        sections: content.sections,
        header: content.header,
        footer: content.footer,
        slug,
        fallback: {
          [CATEGORY_PAGE_GET_EXPERTS_OPTIONS.key]: isBuild
            ? pickRandomNElements(experts, CATEGORY_PAGE_GET_EXPERTS_OPTIONS.params.perPage)
            : experts,
        },
      },
      revalidate: config.pageRevalidationIntervalsSeconds.default,
    };
  } catch (e) {
    const error = normalizeError(e);
    const errorInstance = new UnableToLoadPageDataError(error.message);
    logger.error(errorInstance);
    throw errorInstance;
  }
};

export async function getStaticPaths() {
  const { categoriesService } = initializeCategoriesService();

  const paths = await categoriesService.getStaticPaths();

  return { paths, fallback: 'blocking' };
}
