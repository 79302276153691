import {
  ContentManagerButton,
  ContentManagerImage,
  ApplicationAdvantagesSection,
  FaqSection,
  ProblemsSection,
  SeoTextSection,
  TopicSection,
  WhySection,
  VideoSection,
  ContentManagerMetaData,
  ContentManagerHeader,
  ContentManagerFooter,
} from 'src/core/contentManager/entities';

export enum CategoryPageSectionType {
  START_SECTION = 'category-page-sections.start-section',
  EXPERTS_SECTION = 'category-page-sections.experts-section',
}

type BasicSection = {
  id: number;
  component: CategoryPageSectionType;
};

export type StartSection = BasicSection & {
  title: string;
  description: string;
  imageDesktop: ContentManagerImage;
  imageMobile: ContentManagerImage;
  component: CategoryPageSectionType.START_SECTION;
};

export type ExpertsSection = BasicSection & {
  button: ContentManagerButton;
  component: CategoryPageSectionType.EXPERTS_SECTION;
};

export type CategoryPageSectionsUnion =
  | StartSection
  | ExpertsSection
  | TopicSection
  | ApplicationAdvantagesSection
  | WhySection
  | FaqSection
  | SeoTextSection
  | ProblemsSection
  | VideoSection;

export type CategoryPageSections = Array<CategoryPageSectionsUnion>;

export type CategoryPage = {
  metaData: ContentManagerMetaData;
  sections: CategoryPageSections;
  header: ContentManagerHeader;
  footer: ContentManagerFooter;
};

export type CategoryPageProps = CategoryPage & { slug: string };
